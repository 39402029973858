<template>
  <b-dropdown
    offset="25"
    :text="selectedStatus ? selectedStatus.toUpperCase() : 'Select status'"
    variant="outline-primary"
    class="h-75"
  >
    <b-dropdown-item
      v-for="status in allStatus"
      :key="status"
      @click="statusClick(status)"
    >
      {{ status.toUpperCase() }}
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item @click="statusClick(undefined)">
      Reset to none
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownDivider, BDropdownItem } from "bootstrap-vue";

export default {
  name: "StatusSelector",
  components: {
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
  },
  data() {
    return {
      selectedStatus: null,
    };
  },
  props: {
    allStatus: {
      type: Array,
    },
  },
  methods: {
    statusClick(status) {
      this.$emit("change", { status });
      this.selectedStatus = status;
    },
  },
};
</script>

<style scoped></style>
