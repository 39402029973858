<template>
  <b-table
    class="position-relative"
    :items="data"
    responsive
    primary-key="id"
    :fields="fieldData"
    show-empty
    empty-text="No matching records found. Payment processing takes a few minutes."
  >
    <template #head()="fieldData">
      <table-header-labels
        :fields="fieldData"
        :filter="filter"
        @onSort="$emit('onSort', { ...$event })"
        v-if="filter"
      ></table-header-labels>
    </template>
    <template #cell(payment_id)="data">
      <b-link
        v-if="authUser.is_admin"
        :to="{ name: 'payment', params: { id: data.item.id } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.payment_id }}
      </b-link>
      <template v-else> {{ data.item.payment_id }}</template>
    </template>
    <template #cell(status)="data">
      <div :class="changeColor(data.item.status)">
        {{ data.item.status }}
      </div>
    </template>
    <template #cell(gross_amount)="data">
      <div class="text-nowrap">
        <span :id="'tooltip-target' + data.item.payment_id"
          >{{ data.item.gross_amount }} {{ data.item.currency }}
          <b-tooltip
            :target="'tooltip-target' + data.item.payment_id"
            triggers="hover"
            variant="info"
          >
            Net: {{ data.item.net_amount }} {{ data.item.currency }}, Fee:
            {{ data.item.fee_amount }} {{ data.item.currency }}
          </b-tooltip>
        </span>
      </div>
    </template>
    <template #cell(payed_at)="data">
      {{ $dateHelpers.formatDate(data.item.payed_at) }}
      {{ $dateHelpers.formatTime(data.item.payed_at) }}
    </template>
    <slot name="aftField" />
  </b-table>
</template>

<script>
import { BTable, BTooltip, BLink } from "bootstrap-vue";
import { changeTextColor } from "@/services/utils/util";
import TableHeaderLabels from "../TableHeaderLabels.vue";
export default {
  components: {
    BLink,
    BTable,
    BTooltip,
    TableHeaderLabels,
  },
  data() {
    return {
      fields: [
        { key: "payer_email", label: "Payer email" },
        { key: "payed_at", label: "Payed at" },
        { key: "status", label: "Status" },
        { key: "gross_amount", label: "Gross amount" },
        "",
      ],
    };
  },
  props: {
    data: {
      type: Array,
    },
    prevField: {
      type: Array,
      required: false,
      default: () => [],
    },
    aftField: {
      type: Array,
      required: false,
      default: () => [],
    },
    filter: {
      type: Object,
    },
  },
  computed: {
    fieldData() {
      return [...this.prevField, ...this.fields, ...this.aftField];
    },
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
  },
  methods: {
    changeColor(item) {
      return changeTextColor(item);
    },
  },
};
</script>

<style></style>
