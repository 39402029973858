<template>
  <div class="w-100 d-flex flex-wrap">
    <div class="cols col-sm-12 col-md-5 col-lg-5">
      <label for="start-date">From:</label>
      <b-form-datepicker
        :value="filter.start_date"
        :max="filter.end_date"
        id="start-date"
        @input="$emit('filter-change', { start_date: $event })"
      />
    </div>
    <div class="ml-auto cols col-sm-12 col-md-5 col-lg-5">
      <label for="end-date">To:</label>
      <b-form-datepicker
        :value="filter.end_date"
        :min="filter.start_date"
        id="end-date"
        @input="$emit('filter-change', { end_date: $event })"
      />
    </div>
    <status-selector
      class="cols col-sm-12 col-md-5 col-lg-3 mt-2"
      :all-status="allStatus"
      @change="$emit('filter-change', $event)"
    />
    <search-input
      @search="$emit('filter-change', { search: $event })"
    ></search-input>
  </div>
</template>

<script>
import { BFormDatepicker } from "bootstrap-vue";
import StatusSelector from "@/layouts/components/form/StatusSelector";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
export default {
  components: {
    StatusSelector,
    BFormDatepicker,
    SearchInput,
  },
  props: {
    filter: {
      type: Object,
    },
    extraStatus: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    allStatus() {
      return ["completed", "pending", "failed", ...this.extraStatus];
    },
  },
};
</script>

<style></style>
