<template>
  <div class="ml-2 mb-1 font-weight-bold totals" v-if="total">
    Total income:
    <span v-for="(item, index) in total" :key="item.currency" class="w-100">
      <span class="p-auto h-auto">
        {{ `${item.total.toFixed(2)}` }}
        <span v-if="item.net_total">{{
          `/ ${item.net_total.toFixed(2)}`
        }}</span>
        {{ item.currency }}
        {{ index > total.length - 1 ? ", " : "" }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.totals {
  font-size: 1.4em;
}
</style>
